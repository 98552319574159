.customer {
  background: #f4f4f4;
  margin: 5px;
  padding: 10px 20px;
  /* cursor: pointer; */
  border-radius: 10px;
}

.customer h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.customer p {
  display: inline;
}

.customer.confirmDelete h3 {
  justify-content: center;
}

.customer.confirmDelete .btnOptions {
  display: flex;
  align-items: center;
  justify-content: center;
}
.customer.confirmDelete .btnOptions button {
  margin-right: 20px;
}
