.topnav {
  overflow: hidden;
  width: 100%;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
}

.nav {
  width: 55%;
  margin: 5px auto;
  overflow: auto;
  transition: width 2.5;
}

@media only screen and (max-width: 600px) {
  .nav {
    width: 100%;
    padding: 5px;
  }
}

.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  border-radius: 16px;
}

.topnav .brand {
  font-weight: 800;
}

.topnav .signIn:hover {
  background-color: #f2f2f2;
  color: black;
}

.topnav a.active {
  /* background-color: #3399ff; */
  letter-spacing: 2px;
  color: #3399ff;
  border-radius: 0;
}

.topnav .signIn {
  float: right;
  color: black;
  cursor: pointer;
}

.ng-logo {
  width: 100%;
}
