.contain p {
  margin-bottom: 2rem;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-row div:nth-child(1) {
  margin-right: 2rem;
}
