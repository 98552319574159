.containerLoader {
  /* min-height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  /* height: 100px;
  width: 200px; */
  background: #fff;
  padding: 50px;
  border-radius: 10px;
  position: relative;
  text-align: center;
}

.box svg {
  font-size: 24px;
  animation: spin-animation 1.5s infinite;
  display: inline-block;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
